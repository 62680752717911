.aggregator-block {
    position: relative;
    width: 90%;
    left: 50%;
    top: -5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    transform: translateX(-50%);
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.5);
}

.aggregator-block svg {
    height: 20px;
    width: 20px;
    margin-right: 5px;
}

.aggregator-input {
    margin: 5px 5px 5px 0px;
    height: 20px;
    width: -webkit-fill-available;
    resize: none;
    color: #606060;
    border-radius: 4px;
    text-indent: 5px;
    font-size: 14px;
    transition: 250ms all;
    border: none;
}
