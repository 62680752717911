* {
    margin: 0;
    padding: 0;
    outline: none !important;
    color: #938471;
    font-family: 'Caudex', serif;
}

a {
    text-decoration: none;
    color: inherit;
}

a:hover {
    text-decoration: underline;
}

body,
html {
    width: 100%;
    height: 100vh !important;
    overflow: hidden;
}

#root {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.025);
}

button,
.button {
    border: none !important;
    outline: none !important;
    border-radius: 3px;
    cursor: pointer !important;
    box-shadow: rgb(0 0 0 / 12%) 0px 13px 15px -5px, rgb(0 0 0 / 35%) 0px 8px 8px -5px;
}

select {
    cursor: pointer;
}

.container {
    position: fixed;
    width: 100%;
    height: calc(100vh - 50px);
    top: 50px;
    overflow-y: auto;
    z-index: 5;
}

::-webkit-scrollbar {
    position: absolute;
    width: 4px;
    height: 7px;
}

::-webkit-scrollbar-thumb {
    position: absolute;
    background-color: rgba(120, 120, 120, 0.25);
    border-radius: 0.2em;
}

.header-item {
    height: 35px;
    padding: 0 10px;
    background-color: transparent;
    color: #808080;
    box-shadow: none;
    font-weight: 700;
    font-size: 14px;
    transition: 250ms all;
}

.header-item:hover {
    border: 1px solid #0076b8;
    color: #0076b8;
    box-shadow: 0 2px 4px 0 rgba(0, 118, 184, 0.25);
}

.action-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 45px;
    border-radius: 5px;
    background-color: #ededed;
    color: #000;
    font-size: 14px;
    margin: 5px;
    box-shadow: none;
}

.cancel-btn {
    font-family: 'Comic Neue', cursive;
    color: #b84040 !important;
    font-size: 22px !important;
    margin: 0 15px;
    cursor: pointer;
}

.icon-tooltip {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 0 5px;
    border-radius: 2px;
    font-size: 11px !important;
    color: #0076b8 !important;
    border: 1px solid #0076b8;
    background-color: #fff;
}

.delete {
    color: #b84040 !important;
    border: 1px solid #b84040 !important;
}

.delete:hover {
    box-shadow: 0 2px 4px 0 rgba(184, 64, 64, 0.25) !important;
}

.add {
    color: #0076b8 !important;
    border: 1px solid #0076b8 !important;
    background-color: #fff;
}

.add:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 118, 184, 0.25);
}
