.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.error-container svg {
    width: 75px;
    height: 75px;
    fill: red;
}

.error-container div {
    font-size: 12px;
    color: red;
}
.error-container span {
    color: #000;
    font-size: 14px;
    margin-top: 10px;
}

.error-buttons-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
}

.button-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 5px;
    padding: 5px 5px;
    cursor: pointer;
    border-radius: 3px;
    border: 2px solid rgba(92, 81, 81, 0.25) !important;
    background-color: #0076b8;
    box-shadow: rgb(0 0 0 / 12%) 0px 13px 15px -5px, rgb(0 0 0 / 35%) 0px 8px 8px -5px;
}

.button-content span {
    color: #fff;
    margin: 0 0 0 5px;
}

.button-content svg {
    height: 15px;
    width: 15px;
    fill: #fff;
}
