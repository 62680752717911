.login-container {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.025);
}

.oauth-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 325px;
    height: 215px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0/25%);
    margin: 15px;
}

.oauth-wrapper .input-blocks {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    margin: 10px;
}

.input-blocks input {
    width: 80%;
    height: 35px;
    text-indent: 10px;
    font-size: 14px;
    border-radius: 4px;
    color: #000 !important;
    text-indent: 10px;
    border: 1px solid #c4c4c4;
    background-color: #fff;
}

.oauth-wrapper button:hover,
.input-blocks input:hover,
.input-blocks input:focus {
    border: 1px solid #0076b8 !important;
    box-shadow: 0 2px 4px 0 rgba(0, 118, 184, 0.25);
}

.oauth-wrapper button {
    width: auto !important;
    padding: 10px 20px;
    margin: 10px;
    color: #0076b8 !important;
    font-size: 14px;
    letter-spacing: 5px;
    border-radius: 4px;
    background-color: transparent !important;
    border: 1px solid #0076b8 !important;
    box-shadow: none;
}

@media screen and (max-width: 600px) {
    .oauth-wrapper {
        width: 90%;
        height: 95%;
    }

    .oauth-wrapper button,
    .input-blocks input {
        font-size: 13px;
        height: 45px;
    }

    .oauth-wrapper button {
        width: 80% !important;
        padding: 5px 15px;
    }
}
