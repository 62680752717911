.modal-container {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 5;
}

.modal-body {
    position: absolute;
    width: 450px;
    height: fit-content;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0/25%);
    z-index: 2;
}

.modal-title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 20px;
    padding: 10px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.modal-title p {
    position: absolute;
    left: 50%;
    color: #000;
    font-size: 15px;
    transform: translateX(-50%);
}

.modal-actions {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    bottom: 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
}

.modal-actions button {
    height: 35px;
    margin: 10px;
}

.modal-actions .save {
    margin-right: 5px !important;
}
.modal-actions .cancel {
    margin-left: 5px !important;
}

.modal-content {
    max-height: 350px;
    overflow: hidden;
}

.modal-list-inputs {
    overflow-y: auto;
}

.modal-content,
.modal-input {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    margin-bottom: 10px;
}

.modal-input span {
    font-size: 14px;
    color: #000;
    margin-bottom: 5px;
}

.modal-input textarea {
    height: 300px;
    padding: 10px;
    resize: vertical;
    color: #606060;
    border-radius: 4px;
}

.modal-input input {
    height: 30px;
    text-indent: 10px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    color: #606060;
}

.modal-qr-code-block {
    display: flex;
    justify-content: center;
    img {
        width: 50%;
    }
}

.list-input[aria-disabled='true'] {
    opacity: 0.5;
    filter: grayscale(1);
    .list-input-text-label {
        text-decoration: line-through;
    }
}

.list-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20px;
    padding: 5px 10px;
    border-radius: 4px;
    margin: 0px 15px 0px;
}

.list-input-text,
.list-input-text-label {
    width: 100%;
    display: flex;
    border: none;
    color: #000;
    font-size: 13px;
}

.list-input input {
    margin-right: 10px;
}

.list-input input[type='checkbox'] {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .modal-body {
        width: 80% !important;
    }
}
