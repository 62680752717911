.search-input {
    position: relative;
    height: 30px;
    width: 100%;
    display: flex;
    font-size: 14px;
    border-radius: 4px;
    color: #000 !important;
    text-indent: 10px;
    border: 1px solid #c4c4c4;
    background-color: #fff;
    transition: 250ms all;
}

.search-input:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    border: 1px solid #c4c4c4 !important;
    box-shadow: none !important;
}

.search-input:hover,
.search-input:focus {
    border: 1px solid #0076b8;
    box-shadow: 0 2px 4px 0 rgba(0, 118, 184, 0.25);
}
