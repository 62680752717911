.header-lgt {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 31px;
    padding: 0 10px;
    margin-left: 10px;
    background-color: transparent;
    border: 1px solid #c4c4c4;
    color: #808080;
    box-shadow: none;
    font-weight: 700;
    font-size: 14px;
    transition: 250ms all;
}

.header-lgt:hover {
    border: 1px solid #0076b8;
    box-shadow: 0 2px 4px 0 rgba(0, 118, 184, 0.25);
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 15px;
    padding: 15px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0/25%);
}

.header-backlink {
    position: absolute;
    left: 30px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.header-container span {
    position: absolute;
    left: 50%;
    color: #000;
    transform: translateX(-50%);
}
