.list-counter {
    width: 40px;
    height: 20px;
    border: 1px solid #c4c4c4;
    border-radius: 2px;
    margin-right: 5px;
    margin-left: 5px;
    color: black;
    text-align: center;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    opacity: 1;
}
