.list-container {
    height: calc(100% - 140px);
    padding: 15px;
    margin: 15px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0/25%);
    overflow-y: scroll;
}

.app-link {
    position: relative;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 10px 15px 10px;
    border-radius: 4px;
    padding: 3px 10px;
    border: 1px solid #c4c4c4;
    background-color: #fff;
    cursor: pointer;
}

.app-link p {
    color: #000;
    font-size: 14px;
    cursor: pointer;
    padding: 12px 3px;
    margin-left: -10px;
    white-space: nowrap;
    overflow: hidden;
    text-indent: 10px;
    text-overflow: ellipsis;
}

.app-link-cta {
    display: flex;
    align-items: center;
}

.app-link-cta span {
    color: black;
    font-size: 12px;
}

.app-link-block {
    display: flex;
    flex-direction: column;
}

.app-link-block span {
    color: #000;
    font-size: 14px;
}

.app-link-block-user {
    color: #909090 !important;
    font-size: 12px !important;
}

.app-link:hover,
.list-item:hover {
    border: 1px solid #0076b8 !important;
    box-shadow: 0 2px 4px 0 rgba(0, 118, 184, 0.25);
}

.list-item {
    width: 125px;
    height: 155px;
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    background-color: #fff;
    cursor: pointer;
    overflow: hidden;
}

.list-item {
    margin: 10px 10px 15px 10px;
    padding: 3px 10px;
}

.list-item p {
    text-align: center;
    font-size: 12px;
    color: black;
}

.list-item div {
    font-size: 10px;
    color: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 600px) {
    .app-link {
        height: 60px;
    }
    .app-link p {
        font-size: 12px;
    }
}
