.apps-container {
    display: grid;
    align-items: start;
    align-content: center;
    justify-content: center;
    height: calc(100% - 140px);
    padding: 15px;
    margin: 15px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
    grid-template-columns: repeat(auto-fit, 150px);
    gap: 20px;
}

.app-card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    background-color: #fff;
    border: 1px solid gainsboro;
    cursor: pointer;
    border-radius: 4px;
    color: #000 !important;
    border: 1px solid #000;
    transition: 250ms;
}

.app-card-label {
    position: absolute;
    bottom: 10px;
    font-size: 14px;
    letter-spacing: 1px;
    color: #000;
}

.app-card svg {
    width: 33%;
    height: 33%;
    margin-bottom: 10px;
}

.app-card:hover {
    border-color: #0076b8;
    box-shadow: 0 2px 4px 0 rgba(0, 118, 184, 0.25);
    svg path {
        fill: #0076b8 !important;
    }
    .app-card-label {
        color: #0076b8;
    }
}

.app-card[disabled] {
    border-color: #000;
    filter: grayscale(1);
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: none;
    svg path {
        fill: #000 !important;
    }
}
